import { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { RootState } from '../../../core/RootReducer';
import { SearchBarLocationFilter } from './SearchBarLocationFilter';
import { SearchAndPaginationFilters } from '../../../core/search-pager/SearchPagerActions';
import { SearchPagerLocationFilter } from '../components/TableContainer';
import { MobileApiLocationObject } from '../../../mobile-api-types';
import { getLocationObjectFromLowestLevelPossible } from '../SiteUtils';
import { getResolveData } from '../../../core/route/RouteReducer';

export const LOCATION_FILTER_FORM_ID = 'search-bar-location-filter-form';

interface ExternalProps {
  currentFilters: SearchAndPaginationFilters | null | undefined;
  onApply: (filters: SearchAndPaginationFilters | null) => void;
  filter: SearchPagerLocationFilter;
  key: string;
}

export interface FormState {
  siteId?: string;
  buildingId?: string;
  floorId?: string;
  zoneId?: string;
  location?: MobileApiLocationObject;
}

export const mapStateToProps = (state: RootState, { currentFilters, filter }: ExternalProps) => {
  return {
    initialValues: currentFilters
      ? {
          siteId: currentFilters[`${filter.name}siteId`]?.value,
          buildingId: currentFilters[`${filter.name}buildingId`]?.value,
          floorId: currentFilters[`${filter.name}floorId`]?.value,
          zoneId: currentFilters[`${filter.name}zoneId`]?.value,
          // This is getting the resolved data from the LocationFilterChip rendered for this filter
          location: getLocationObjectFromLowestLevelPossible(
            getResolveData(state, `${filter.name}site`),
            getResolveData(state, `${filter.name}building`),
            getResolveData(state, `${filter.name}floor`),
            getResolveData(state, `${filter.name}zone`)
          ),
        }
      : {},
  };
};

export type SearchBarLocationFilterProps = ExternalProps &
  ReturnType<typeof mapFormStateToProps> &
  Pick<InjectedFormProps<FormState>, 'change'>;

export const mapFormStateToProps = (state: RootState) => ({
  formValues: getFormValues(LOCATION_FILTER_FORM_ID)(state) as FormState,
});

export const SearchBarLocationFilterContainer = compose<ComponentClass<ExternalProps>>(
  connect(mapStateToProps),
  reduxForm<FormState>({
    form: LOCATION_FILTER_FORM_ID,
  }),
  connect(mapFormStateToProps)
)(SearchBarLocationFilter);
