import { DropdownMenuItem } from 'singlewire-components';
import { SearchAndPaginationFilters } from '../../../core/search-pager/SearchPagerActions';
import { SearchPagerStringFilter } from '../components/TableContainer';
import { ORDER_MODES, localizeOrderMode, updateFiltersForString } from './SearchBarFilterUtils';

export const SearchBarStringFilter = ({
  filter,
  currentFilters,
  onApply,
}: {
  filter: SearchPagerStringFilter;
  currentFilters?: SearchAndPaginationFilters | null;
  onApply: (filters: SearchAndPaginationFilters | null) => void;
}) => {
  const order = currentFilters?.[`${filter.name}`]?.value;

  return (
    <>
      {ORDER_MODES.map(mode => (
        <DropdownMenuItem
          variant="checkmark"
          onClick={() =>
            order !== mode
              ? onApply(updateFiltersForString(currentFilters, filter, mode))
              : onApply(updateFiltersForString(currentFilters, filter, null))
          }
          key={mode.toString()}
          id={mode.toString()}
          label={localizeOrderMode(mode)}
          checked={order === mode}
        />
      ))}
    </>
  );
};
