import moment from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  DropdownMenuItem,
  Icon,
  StaticDatePicker,
  StaticDateRangePicker,
} from 'singlewire-components';
import { common_t } from '../../../CommonLocale';
import {
  SearchAndPaginationFilters,
  SingleSelectFilter,
} from '../../../core/search-pager/SearchPagerActions';
import { SaveButton } from '../components/Button';
import { SearchPagerDateFilter } from '../components/TableContainer';
import {
  DATE_VIEW_MODES,
  DateViewMode,
  getDateViewMode,
  localizeDateViewMode,
  updateFiltersForDate,
} from './SearchBarFilterUtils';

const CustomDateFilter = ({
  filter,
  currentFilters,
  onApply,
  multiple,
}: {
  filter: SearchPagerDateFilter;
  currentFilters?: SearchAndPaginationFilters | null;
  onApply: (filters: SearchAndPaginationFilters | null) => void;
  multiple: boolean;
}) => {
  const [startDate, setStartDate] = useState(moment().startOf('day').subtract(14, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().startOf('day').subtract(7, 'days').toDate());

  const onChangeDateRange = (range: any) => {
    setStartDate(range?.from);
    setEndDate(range.to);
  };

  // prettier-ignore
  const submit = useCallback(() => {
    if (multiple) {
      onApply(updateFiltersForDate(currentFilters, filter, startDate.toISOString(), moment(endDate).clone().add(1, 'days').toISOString()));
    } else {
      onApply(updateFiltersForDate(currentFilters, filter, startDate.toISOString(), moment(startDate).clone().add(1, 'days').toISOString()));
    }
  }, [multiple, onApply, currentFilters, filter, startDate, endDate]);
  const applyDisabled = useMemo(
    () => (multiple ? moment(endDate).isSameOrBefore(startDate) : false),
    [multiple, endDate, startDate]
  );
  return (
    <>
      {multiple ? (
        <StaticDateRangePicker
          value={{ from: startDate, to: endDate }}
          onChange={onChangeDateRange}
          disablePast={false}
          disableFuture={true}
        />
      ) : (
        <StaticDatePicker
          value={startDate}
          onChange={setStartDate}
          disablePast={false}
          disableFuture={true}
        />
      )}

      <Box align="end" mr="md" mb="md">
        <SaveButton
          id="apply-filter"
          variant="contained"
          color="primary"
          label={common_t(['button', 'apply'])}
          disabled={applyDisabled}
          onClick={submit}
        />
      </Box>
    </>
  );
};

export const getDateFilter = (
  currentFilters: SearchAndPaginationFilters | null | undefined,
  name: string
): SingleSelectFilter | null => {
  const filter = currentFilters?.[name];
  if (filter?.type === 'date') {
    return filter;
  }
  return null;
};

const getApply = (
  dateViewMode: DateViewMode,
  currentDateViewMode: DateViewMode,
  onApply: (filters: SearchAndPaginationFilters | null) => void,
  filter: SearchPagerDateFilter,
  currentFilters?: SearchAndPaginationFilters | null
) => {
  const today = moment().startOf('day');
  const none = onApply(updateFiltersForDate(currentFilters, filter, null, null));
  switch (dateViewMode) {
    case DateViewMode.TODAY:
      return currentDateViewMode !== dateViewMode
        ? onApply(updateFiltersForDate(currentFilters, filter, today.toISOString(), null))
        : none;
    case DateViewMode.YESTERDAY:
      return currentDateViewMode !== dateViewMode
        ? onApply(
            updateFiltersForDate(
              currentFilters,
              filter,
              today.clone().subtract(1, 'days').toISOString(),
              today.clone().toISOString()
            )
          )
        : none;
    case DateViewMode.LAST_SEVEN_DAYS:
      return currentDateViewMode !== dateViewMode
        ? onApply(
            updateFiltersForDate(
              currentFilters,
              filter,
              today.clone().subtract(7, 'days').toISOString(),
              null
            )
          )
        : none;
    case DateViewMode.LAST_THIRTY_DAYS:
      return currentDateViewMode !== dateViewMode
        ? onApply(
            updateFiltersForDate(
              currentFilters,
              filter,
              today.clone().subtract(30, 'days').toISOString(),
              null
            )
          )
        : none;
    case DateViewMode.LAST_TWELVE_MONTHS:
      return currentDateViewMode !== dateViewMode
        ? onApply(
            updateFiltersForDate(
              currentFilters,
              filter,
              today.clone().subtract(1, 'years').toISOString(),
              null
            )
          )
        : none;
  }
};

export const SearchBarDateFilter = ({
  filter,
  currentFilters,
  onApply,
}: {
  filter: SearchPagerDateFilter;
  currentFilters?: SearchAndPaginationFilters | null;
  onApply: (filters: SearchAndPaginationFilters | null) => void;
}) => {
  const startFilter = getDateFilter(currentFilters, `${filter.name}__gte`);
  const endFilter = getDateFilter(currentFilters, `${filter.name}__lt`);
  const currentDateViewMode = useMemo(
    () => getDateViewMode(startFilter?.value, endFilter?.value),
    [startFilter, endFilter]
  );

  return (
    <>
      {DATE_VIEW_MODES.map(dateViewMode => (
        <DropdownMenuItem
          key={dateViewMode + 'DropdownMenuItem'}
          onClick={() =>
            getApply(dateViewMode, currentDateViewMode, onApply, filter, currentFilters)
          }
          variant={
            dateViewMode === DateViewMode.CUSTOM_DATE_RANGE ||
            dateViewMode === DateViewMode.CUSTOM_DATE
              ? 'subTrigger'
              : 'checkmark'
          }
          icon={
            currentDateViewMode === dateViewMode &&
            (dateViewMode === DateViewMode.CUSTOM_DATE_RANGE ||
              dateViewMode === DateViewMode.CUSTOM_DATE) && <Icon.Save size="sm" />
          }
          inset={
            currentDateViewMode !== dateViewMode &&
            (dateViewMode === DateViewMode.CUSTOM_DATE_RANGE ||
              dateViewMode === DateViewMode.CUSTOM_DATE)
          }
          id={dateViewMode.toString()}
          label={localizeDateViewMode(dateViewMode)}
          checked={currentDateViewMode === dateViewMode}
          includeArrow
          fixedMaxHeight={false}>
          <CustomDateFilter
            filter={filter}
            currentFilters={currentFilters}
            onApply={onApply}
            multiple={dateViewMode === DateViewMode.CUSTOM_DATE_RANGE}
          />
        </DropdownMenuItem>
      ))}
    </>
  );
};
