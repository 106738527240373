import { DropdownMenuItem } from 'singlewire-components';
import { SearchAndPaginationFilters } from '../../../core/search-pager/SearchPagerActions';
import { updateFiltersForAttribute } from './SearchBarFilterUtils';
import { SearchPagerAttributeFilter } from '../components/TableContainer';

export const SearchBarAttributeFilter = ({
  filter,
  currentFilters,
  onApply,
}: {
  filter: SearchPagerAttributeFilter;
  currentFilters?: SearchAndPaginationFilters | null;
  onApply: (filters: SearchAndPaginationFilters | null) => void;
}) => {
  const option = currentFilters?.[`${filter.name}`]?.value;

  return (
    <>
      {filter.options.map(x => (
        <DropdownMenuItem
          onClick={() =>
            option === x
              ? onApply(updateFiltersForAttribute(currentFilters, filter, null))
              : onApply(updateFiltersForAttribute(currentFilters, filter, x))
          }
          variant="checkmark"
          id={x}
          key={x}
          checked={option === x}
          label={filter.localizeOption(x)}
        />
      ))}
    </>
  );
};
