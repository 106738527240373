import { useCallback } from 'react';
import { Field } from 'redux-form';
import { Box } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { SinglewireForm } from '../../../core/forms/SinglewireForm';
import { noop } from '../../../core/utils/common';
import { MobileApiLocationObject } from '../../../mobile-api-types';
import { SaveButton } from '../components/Button';
import { RemoteSelectContainer } from '../components/redux-form/RemoteSelectContainer';
import { updateFiltersForLocation } from './SearchBarFilterUtils';
import {
  LOCATION_FILTER_FORM_ID,
  SearchBarLocationFilterProps,
} from './SearchBarLocationFilterContainer';

const Container = styled.div`
  min-width: 300px;
  margin: 16px;
`;

/**
 * FormValues plays weirdly with being set by initialValues - you can get in situations where we have a Zone and a Floor but no Building
 * An example is when the user opens a filter that's already set and deletes the building - the secondaryModel doesn't update correctly
 * To make this work correctly we have to convert the Location value to trim the "lower" layers when an "upper" layer is removed (trim out floor and zone when building is removed)
 */
const ensureSecondaryModelMatchesFormValues = (location: MobileApiLocationObject | undefined) => {
  if (!location || !location.site) {
    return undefined;
  }

  if (!location.building) {
    return { site: { ...location.site } } as MobileApiLocationObject;
  }

  if (!location.floor) {
    return {
      site: { ...location.site },
      building: { ...location.building },
    } as MobileApiLocationObject;
  }

  return location;
};

export const SearchBarLocationFilter = ({
  filter,
  currentFilters,
  onApply,
  formValues,
  change,
}: SearchBarLocationFilterProps) => {
  const location = formValues?.location;
  const submit = useCallback(() => {
    onApply(
      updateFiltersForLocation(
        currentFilters,
        filter,
        ensureSecondaryModelMatchesFormValues(location)
      )
    );
  }, [location, onApply, currentFilters, filter]);

  return (
    <Container>
      <SinglewireForm formId={LOCATION_FILTER_FORM_ID} noValidate={true} onSubmit={noop}>
        <Field
          id="siteId"
          name="siteId"
          label={common_t(['resource', 'site'])}
          component={RemoteSelectContainer}
          xs={12}
          props={{
            isClearable: true,
            placeholder: common_t(['label', 'select']),
            secondaryModel: `location.site`,
            isMulti: false,
            apiRequest: ['sites', [], 'GET'],
            menuPortalTarget: document.body,
          }}
          onChange={() => {
            change(`buildingId`, null);
            change(`location.building`, undefined);
            change(`floorId`, null);
            change(`location.floor`, undefined);
            change(`zoneId`, null);
            change(`location.zone`, undefined);
          }}
        />
        {formValues?.siteId && (
          <Field
            id="buildingId"
            name="buildingId"
            label={common_t(['resource', 'siteBuilding'])}
            component={RemoteSelectContainer}
            xs={12}
            props={{
              isClearable: true,
              placeholder: common_t(['label', 'select']),
              secondaryModel: `location.building`,
              isMulti: false,
              apiRequest: ['siteBuildings', [formValues.siteId], 'GET'],
              menuPortalTarget: document.body,
            }}
            onChange={() => {
              change(`floorId`, null);
              change(`location.floor`, undefined);
              change(`zoneId`, null);
              change(`location.zone`, undefined);
            }}
          />
        )}
        {formValues?.buildingId && (
          <Field
            id="floorId"
            name="floorId"
            label={common_t(['resource', 'siteFloor'])}
            component={RemoteSelectContainer}
            xs={12}
            props={{
              isClearable: true,
              placeholder: common_t(['label', 'select']),
              secondaryModel: `location.floor`,
              isMulti: false,
              apiRequest: ['siteBuildingFloors', [formValues.siteId, formValues.buildingId], 'GET'],
              menuPortalTarget: document.body,
            }}
            onChange={() => {
              change(`zoneId`, null);
              change(`location.zone`, undefined);
            }}
          />
        )}
        {formValues?.floorId && (
          <Field
            id="zoneId"
            name="zoneId"
            label={common_t(['resource', 'siteZone'])}
            component={RemoteSelectContainer}
            xs={12}
            props={{
              isClearable: true,
              placeholder: common_t(['label', 'select']),
              secondaryModel: `location.zone`,
              isMulti: false,
              apiRequest: [
                'siteBuildingFloorZones',
                [formValues.siteId, formValues.buildingId, formValues.floorId],
                'GET',
              ],
              menuPortalTarget: document.body,
            }}
          />
        )}
        <Box align="end" mb="md">
          <SaveButton
            id="apply-filter"
            variant="contained"
            color="primary"
            label={common_t(['button', 'apply'])}
            disabled={!formValues?.siteId}
            onClick={submit}
          />
        </Box>
      </SinglewireForm>
    </Container>
  );
};
