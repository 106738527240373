import { LocationDescriptor } from 'history';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  ButtonProps,
  Icon,
  IconButton,
  IconButtonProps,
  Button as RegistryButton,
} from 'singlewire-components';
import { common_t } from '../../../CommonLocale';

// NOTE: If you are here and your dropdown isn't working with these buttons as a trigger
// make sure your button forwards the ref the same as the example below:

export const Button = React.forwardRef((props: ButtonProps & { id: string }, ref: any) => {
  const { children, ...rest } = props;

  return (
    <RegistryButton {...{ ref }} {...rest}>
      {children}
    </RegistryButton>
  );
});

export const AddButton = (props: ButtonProps & { id: string }) => {
  const { children, ...rest } = props;

  return (
    <RegistryButton color="inherit" startIcon={<Icon.Add color="inherit" />} {...rest}>
      {children}
    </RegistryButton>
  );
};

export const RemoveButton = (props: ButtonProps & { id: string }) => {
  const { children, ...rest } = props;

  return (
    <RegistryButton color="inherit" startIcon={<Icon.Remove color="inherit" />} {...rest}>
      {children}
    </RegistryButton>
  );
};

export const EditButton = (props: Omit<ButtonProps, 'label'> & { id: string }) => {
  return (
    <RegistryButton
      label={common_t(['tooltips', 'edit'])}
      startIcon={<Icon.Edit color="inherit" />}
      {...props}
    />
  );
};

export const ManageButton = (props: Omit<ButtonProps, 'label'> & { id: string }) => {
  return (
    <RegistryButton
      color="gray"
      label={common_t(['tooltips', 'manage'])}
      startIcon={<Icon.Admin color="inherit" />}
      {...props}
    />
  );
};

export const ViewButton = (props: ButtonProps & { id: string }) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.View color="inherit" />}
      label={props.label || common_t(['tooltips', 'view'])}
    />
  );
};

export const SaveButton = ({
  hideIcon,
  startIcon,
  ...props
}: Omit<ButtonProps, 'label'> & { label?: string; hideIcon?: boolean; id: string }) => {
  return (
    <RegistryButton
      {...props}
      startIcon={startIcon ? startIcon : !hideIcon && <Icon.Save color="inherit" />}
      label={props.label || common_t(['button', 'save'])}
    />
  );
};

export const CancelButton = (
  props: Omit<ButtonProps, 'label'> & { label?: string; id: string }
) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.Cancel color="inherit" size={props.size} />}
      label={props.label || common_t(['button', 'cancel'])}
    />
  );
};

export const UploadButton = (
  props: Omit<ButtonProps, 'label'> & { label?: string; id: string }
) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.Upload color="inherit" />}
      label={props.label || common_t(['button', 'upload'])}
    />
  );
};

export const DeleteButton = (
  props: Omit<ButtonProps, 'label'> & { label?: string; id: string }
) => {
  return (
    <RegistryButton
      color="gray"
      {...props}
      startIcon={<Icon.Delete color="inherit" size="sm" />}
      label={props.label || common_t(['button', 'delete'])}
    />
  );
};

export const DownloadButton = (
  props: Omit<ButtonProps, 'label'> & { label?: string; id: string }
) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.Download color="inherit" size="sm" />}
      label={props.label || common_t(['button', 'download'])}
    />
  );
};

export const CopyButton = (props: Omit<ButtonProps, 'label'> & { label?: string; id: string }) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.Copy color="inherit" />}
      label={props.label || common_t(['button', 'copy'])}
    />
  );
};
export const MoveUpButton = (
  props: Omit<ButtonProps, 'label'> & { label?: string; id: string }
) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.MoveUp color="inherit" />}
      label={props.label || common_t(['button', 'copy'])}
    />
  );
};

export const ReconnectButton = (props: ButtonProps & { id: string }) => {
  return (
    <RegistryButton
      {...props}
      color="gray"
      startIcon={<Icon.LinkSlash color="inherit" />}
      label={props.label || common_t(['button', 'reconnect'])}
    />
  );
};

export const WandMagicSparklesButton = (props: ButtonProps) => {
  return (
    <RegistryButton
      {...props}
      color="gray"
      startIcon={<Icon.WandMagicSparkles color="inherit" />}
    />
  );
};

export const RestartButton = (props: ButtonProps & { id: string }) => {
  return (
    <RegistryButton
      {...props}
      startIcon={<Icon.Restart color="inherit" />}
      label={props.label || common_t(['button', 'reboot'])}
    />
  );
};

export const VolumeButton = (props: ButtonProps & { id: string; label?: string }) => {
  return <RegistryButton {...props} startIcon={<Icon.Volume color="inherit" />} />;
};

export const AddIconButton = (props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }) => {
  return (
    <IconButton
      label={common_t(['button', 'add'])}
      {...props}
      color="gray"
      icon={<Icon.Add size={props.size} color="inherit" />}></IconButton>
  );
};

export const RefreshIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string; label?: string }
) => {
  return (
    <IconButton
      color="gray"
      {...props}
      icon={<Icon.Refresh color="inherit" size="sm" />}
      label={props.label || common_t(['label', 'refresh'])}
    />
  );
};

export const RemoveIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['button', 'remove'])}
      {...props}
      color="gray"
      icon={<Icon.Remove size={props.size} color="inherit" />}></IconButton>
  );
};

export const SaveIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['button', 'save'])}
      {...props}
      color="gray"
      icon={<Icon.Save size={props.size} color="inherit" />}></IconButton>
  );
};

export const CancelIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['button', 'cancel'])}
      {...props}
      color="gray"
      icon={<Icon.Cancel size={props.size} color="inherit" />}></IconButton>
  );
};

export const EditIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['tooltips', 'edit'])}
      {...props}
      color="gray"
      icon={<Icon.Edit size={props.size} color="inherit" />}></IconButton>
  );
};

export const DeleteIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['tooltips', 'delete'])}
      {...props}
      color="gray"
      icon={<Icon.Delete size={props.size} color="inherit" />}></IconButton>
  );
};

export const LocationIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['tooltips', 'location'])}
      {...props}
      color="gray"
      icon={<Icon.FocusLocation size={props.size} color="inherit" />}></IconButton>
  );
};

export const SetLocationIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['tooltips', 'editLocation'])}
      {...props}
      color="gray"
      icon={<Icon.LocationDot size={props.size} color="inherit" />}></IconButton>
  );
};

export const UpIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return (
    <IconButton
      {...props}
      color="gray"
      icon={<Icon.Collapse size={props.size} color="inherit" />}></IconButton>
  );
};

export const CopyIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      label={props.label || common_t(['label', 'copy'])}
      color="gray"
      {...props}
      icon={<Icon.Copy size={props.size} color="inherit" />}></IconButton>
  );
};

export const DownIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return <IconButton {...props} color="inherit" icon={<Icon.Expand />}></IconButton>;
};

export const ExternalLinkIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['tooltips', 'openLink'])}
      {...props}
      color="gray"
      icon={<Icon.ExternalLink size={props.size} color="inherit" />}></IconButton>
  );
};

export const ProhibitIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return (
    <IconButton
      {...props}
      color="gray"
      icon={<Icon.Prohibited size={props.size} color="inherit" />}></IconButton>
  );
};

export const PromoteIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return (
    <IconButton
      {...props}
      color="gray"
      icon={<Icon.Promote size={props.size} color="inherit" />}></IconButton>
  );
};

export const DemoteIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return (
    <IconButton
      {...props}
      color="gray"
      icon={<Icon.Demote size={props.size} color="inherit" />}></IconButton>
  );
};

export const FollowUpIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return (
    <IconButton
      {...props}
      color="gray"
      icon={<Icon.FollowUp size={props.size} color="inherit" />}></IconButton>
  );
};

export const DownloadIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string; label?: string }
) => {
  return (
    <IconButton
      {...props}
      label={props.label || common_t(['label', 'download'])}
      color="gray"
      icon={<Icon.Download size={props.size} color="inherit" />}></IconButton>
  );
};

export const ViewIconButton = (props: Omit<IconButtonProps, 'icon'> & { id: string }) => {
  return (
    <IconButton
      {...props}
      label={props.label || common_t(['label', 'view'])}
      color="gray"
      icon={<Icon.View size={props.size} />}></IconButton>
  );
};

export const MoveUpIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['tooltips', 'moveUp'])}
      {...props}
      icon={<Icon.MoveUp size={props.size} color="inherit" />}></IconButton>
  );
};

export const PlayOutlineIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      {...props}
      label={props.label || common_t(['label', 'play'])}
      color="gray"
      icon={<Icon.PlayOutline size={props.size} color="inherit" />}></IconButton>
  );
};
export const PauseIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      {...props}
      label={props.label || common_t(['label', 'pause'])}
      color="gray"
      icon={<Icon.Paused size={props.size} color="inherit" />}></IconButton>
  );
};
export const PlayFilledIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { label?: string; id: string }
) => {
  return (
    <IconButton
      {...props}
      label={props.label || common_t(['label', 'play'])}
      color="gray"
      icon={<Icon.PlayFilled size={props.size} color="inherit" />}></IconButton>
  );
};

export const MoveDownIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['tooltips', 'moveDown'])}
      {...props}
      icon={<Icon.MoveDown size={props.size} color="inherit" />}></IconButton>
  );
};

export const MoveToTopIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['tooltips', 'moveToTop'])}
      {...props}
      icon={<Icon.MoveToTop size={props.size} color="inherit" />}></IconButton>
  );
};

export const MoveToBottomIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['tooltips', 'moveToBottom'])}
      {...props}
      icon={<Icon.MoveToBottom size={props.size} color="inherit" />}></IconButton>
  );
};

export const HelpIconButton = (props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }) => {
  return (
    <IconButton
      label={common_t(['button', 'help'])}
      {...props}
      icon={<Icon.Help size={props.size} color="inherit" />}
    />
  );
};

export const RestartIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['button', 'reboot'])}
      {...props}
      icon={<Icon.Restart size={props.size} color="inherit" />}
    />
  );
};

export const VolumeIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['button', 'adjustVolume'])}
      {...props}
      icon={<Icon.Volume size={props.size} color="inherit" />}
    />
  );
};

export const UploadIconButton = (
  props: Omit<IconButtonProps, 'label' | 'icon'> & { id: string }
) => {
  return (
    <IconButton
      label={common_t(['button', 'bulkAdd'])}
      {...props}
      icon={<Icon.Upload size={props.size} color="inherit" />}
    />
  );
};

const LinkButtonComponent = (
  props: ButtonProps &
    RouteComponentProps & {
      to: LocationDescriptor<unknown>;
    } & { id: string; showIcon?: boolean }
) => {
  const {
    children,
    label,
    history,
    location,
    match,
    staticContext,
    to,
    showIcon = true,
    onClick,
    ...rest
  } = props;

  return (
    <RegistryButton
      color="gray"
      startIcon={showIcon && <Icon.Add color="inherit" />}
      label={label}
      {...rest}
      asChild>
      <Link to={to}>{children || label}</Link>
    </RegistryButton>
  );
};

export const LinkButton = withRouter(LinkButtonComponent);

const LinkIconButtonComponent = (
  props: IconButtonProps &
    RouteComponentProps & {
      to: LocationDescriptor<unknown>;
    } & { id: string }
) => {
  const { children, history, location, match, staticContext, to, onClick, ...rest } = props;

  return (
    <IconButton color="gray" {...rest} asChild>
      <Link to={to}>{children || rest.icon}</Link>
    </IconButton>
  );
};

export const LinkIconButton = withRouter(LinkIconButtonComponent);
