import { Field } from 'redux-form';
import { Box, Typography } from 'singlewire-components';
import { SinglewireForm } from '../../core/forms/SinglewireForm';
import { StickyFormButtons } from '../shared/components/StickyFormButtons';
import { TextField } from '../shared/components/redux-form/TextField';
import { CancelOrExpireFormProps } from './CancelOrExpireFormContainer';
import { layout_t } from './LayoutLocale';

export const FORM_ID = 'cancel-or-expire-form';

export const CancelOrExpireForm = ({
  hideModal,
  handleSubmit,
  onSubmit,
  submitting,
  type,
}: CancelOrExpireFormProps) => (
  <SinglewireForm
    formId={FORM_ID}
    className="form-horizontal"
    noValidate={true}
    onSubmit={handleSubmit(values => onSubmit(FORM_ID, values))}>
    <Box mb="sm">
      <Typography variant="subtitle">{layout_t(['cancelOrExpire', type, 'subtitle'])}</Typography>
    </Box>
    <Field
      id="comment"
      name="comment"
      label={layout_t(['cancelOrExpire', 'comment'])}
      component={TextField}
      maxLength={140}
      placeholder={layout_t(['cancelOrExpire', 'comment'])}
    />
    <StickyFormButtons cancelClickAction={hideModal} submitting={submitting} />
  </SinglewireForm>
);
