import { Component } from 'react';
import { Dropdown, DropdownMenuItem, Icon, IconButton } from 'singlewire-components';
import { common_t } from '../../../CommonLocale';
import { SearchAndPaginationFilters } from '../../../core/search-pager/SearchPagerActions';
import { SearchPagerFilter } from '../components/TableContainer';
import { SearchBarAttributeFilter } from './SearchBarAttributeFilter';
import { SearchBarDateFilter } from './SearchBarDateFilter';
import { SearchBarMultiSelectFilter } from './SearchBarMultiSelectFilter';
import { SearchBarStringFilter } from './SearchBarStringFilter';
import { SearchBarLocationFilterContainer } from './SearchBarLocationFilterContainer';

interface FilterDropdownContainerProps {
  availableFilters: SearchPagerFilter[];
  currentFilters?: SearchAndPaginationFilters | null;
  onSearchPagerChangeFilters: (filters: SearchAndPaginationFilters | null) => void;
}

const getActiveFilter = (
  filter: SearchPagerFilter,
  currentFilters?: SearchAndPaginationFilters | null
) => {
  switch (filter.type) {
    case 'date': {
      return currentFilters?.[`${filter.name}__gte`]?.type === 'date';
    }
    case 'multi': {
      return currentFilters?.[`${filter.name}`]?.type === 'multi';
    }
    case 'string': {
      return currentFilters?.[`${filter.name}`]?.type === 'string';
    }
    case 'attribute': {
      return currentFilters?.[`${filter.name}`]?.type === 'attribute';
    }
    case 'location': {
      return currentFilters?.[`${filter.name}siteId`]?.type === 'location';
    }
    default:
      return false;
  }
};
const notFixedMaxHeightTypes: SearchPagerFilter['type'][] = ['location'];

// Note, this must be a class component for some reason to bootstrap dropdown to work
// Note - there is a jQuery hack here so that the dropdown plays nicely and doesn't magically
// close for no reason. Once we get rid of jquery bootstrap functionality we can remove this hack!
export class SearchBarFilterDropdownContainer extends Component<FilterDropdownContainerProps> {
  constructor(props: Readonly<FilterDropdownContainerProps>) {
    super(props);
    this.onApply = this.onApply.bind(this);
  }

  onApply(filters: SearchAndPaginationFilters | null) {
    this.props.onSearchPagerChangeFilters(filters);
  }

  getFilterSubMenu = (filter: SearchPagerFilter) => {
    switch (filter.type) {
      case 'date':
        return (
          <SearchBarDateFilter
            currentFilters={this.props.currentFilters}
            onApply={this.onApply}
            filter={filter}
            key="date"
          />
        );
      case 'string':
        return (
          <SearchBarStringFilter
            currentFilters={this.props.currentFilters}
            onApply={this.onApply}
            filter={filter}
            key="string"
          />
        );
      case 'attribute':
        return (
          <SearchBarAttributeFilter
            currentFilters={this.props.currentFilters}
            onApply={this.onApply}
            filter={filter}
            key="attribute"
          />
        );
      case 'location':
        return (
          <SearchBarLocationFilterContainer
            currentFilters={this.props.currentFilters}
            onApply={this.onApply}
            filter={filter}
            key="location"
          />
        );
      case 'multi':
        return (
          <SearchBarMultiSelectFilter
            currentFilters={this.props.currentFilters}
            onApply={this.onApply}
            filter={filter}
            key="multi"
          />
        );
      default:
        return <></>;
    }
  };

  render() {
    return (
      <Dropdown
        id="filter-dropdown"
        trigger={() => (
          <IconButton
            id="filter-dropdown-button"
            label={common_t(['label', 'filters'])}
            icon={<Icon.Filter color="inherit" />}
            disableRadixTooltip={true}
          />
        )}>
        <DropdownMenuItem key="filter=by" variant="label" id="filter-by" label="Filter By" />
        <DropdownMenuItem key="filter-divider" variant="divider" id="filter-divider" />
        {this.props.availableFilters.map(filter => {
          const isActive = getActiveFilter(filter, this.props.currentFilters);
          return (
            <DropdownMenuItem
              key={filter.name + 'DropdownMenuItem'}
              variant="subTrigger"
              inset={true}
              icon={isActive && <Icon.Save size="sm" />}
              id={filter.id || filter.name}
              label={filter.label}
              includeArrow={true}
              onClick={() => this.setState({ active: filter.name })}
              fixedMaxHeight={filter.type in notFixedMaxHeightTypes}>
              {[this.getFilterSubMenu(filter)]}
            </DropdownMenuItem>
          );
        })}
      </Dropdown>
    );
  }
}
