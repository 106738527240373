import { AxiosError } from 'axios';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { combineLatest, from } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { PermissionDeniedError } from '../../common-types';
import { MobileApiSession } from '../../mobile-api-types';
import { fetchBranding } from '../branding/BrandingActions';
import { core_t } from '../CoreLocale';
import { initializeDomains } from '../domains/DomainsActions';
import { initializeFacilities } from '../facilities/FacilitiesActions';
import { refreshIcopServers } from '../icop-servers/ICOPServersActions';
import { growl, initializeLayout, LayoutType } from '../layout/LayoutActions';
import {
  closeDismissibleComponents,
  locationChangeComplete,
  navigateTo,
} from '../navigation/NavigationActions';
import { refreshPermissions, startPermissionsWatchers } from '../permissions/PermissionsActions';
import { RootState } from '../RootReducer';
import Routes from '../route/Routes';
import { restoreScroll, setManualScrollRestoration } from '../scroll/ScrollActions';
import { startSessionWatchers, updateSession } from '../session/SessionActions';
import apiClient from '../utils/api-client';
import { hidePageLoader } from '../utils/common';
import log from '../utils/log';
import { getSkipAutoScrollToTopForUrlPrefixes } from '../utils/ScrollUtils';
import { isSelfServiceMode } from '../utils/session';
import {
  initialize,
  initializeComplete,
  initializePendo,
  inspectSession,
  setInitializeComplete,
} from './InitializedActions';
import { InitializeAction } from './InitializedReducer';

export const initializeEpic: Epic<InitializeAction, Action, RootState, any> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(initialize)),
    switchMap(() => {
      // Start shipping logs to the server when our app becomes stable
      window.logInterceptor.startShippingLogsToServer();

      const body = window.document.getElementsByTagName('body')[0] as HTMLElement;
      const html = window.document.getElementsByTagName('html')[0] as HTMLElement;
      const isSelfService = isSelfServiceMode();

      // Add our mobile browser class when necessary (TOOD: add to store)
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        )
      ) {
        html.classList.add('ismobile');
      } else {
        html.classList.remove('ismobile');
      }

      combineLatest([
        store$.pipe(map(state => state.layout.layoutType)),
        store$.pipe(map(state => state.layout.rightSidebarOpened)),
        store$.pipe(map(state => state.session)),
      ]).subscribe(([layoutType, rightSidebarOpened, session]) => {
        const isLoggedIn = !!session;

        if (!isLoggedIn) {
          html.classList.add('login-content');
          body.classList.add('login-content');
        } else {
          html.classList.remove('login-content');
          body.classList.remove('login-content');
        }

        if (!isSelfService && isLoggedIn && rightSidebarOpened === true) {
          body.classList.add('right-nav-toggled');
        } else {
          body.classList.remove('right-nav-toggled');
        }

        if (!isSelfService && isLoggedIn && layoutType === LayoutType.StretchedLayout) {
          body.classList.add('left-nav-toggled');
        } else {
          body.classList.remove('left-nav-toggled');
        }
      });

      return [initializeLayout(), inspectSession()];
    })
  );

export const initializePendoEpic: Epic<InitializeAction, Action, RootState, any> = (
  action$,
  store$
) =>
  action$.pipe(
    filter(isActionOf(initializePendo)),
    withLatestFrom(store$.pipe(map(state => state.session))),
    switchMap(([_, session]) => {
      // @ts-ignore
      window.pendo.initialize({
        apiKey: '63ec0b1b-76fa-4176-7535-b924a692553e',
        visitor: {
          id: session?.userId,
        },
        account: {
          id: session?.providerId,
          name: session?.providerName,
        },
      });
      return [];
    })
  );

export const inspectSessionEpic: Epic<InitializeAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(inspectSession)),
    switchMap(() => {
      const defaultAction = initializeComplete(null);
      const errorActions = [navigateTo(Routes.Exception), defaultAction];

      // Getting our current session is the only way to know if we're "really" initialized and logged in. If the cookie is properly set, we'll get a session back.
      return from(apiClient.get<MobileApiSession>('/session')).pipe(
        switchMap(response => {
          return [
            updateSession(response.data),
            initializeDomains(),
            initializeFacilities(),
            initializePendo(),
            refreshPermissions({
              useCacheIfAvailable: true,
              cb: refreshPermissionsError =>
                refreshPermissionsError
                  ? errorActions
                  : [
                      refreshIcopServers({
                        cb: refreshServersError =>
                          refreshServersError &&
                          !(refreshServersError instanceof PermissionDeniedError)
                            ? errorActions
                            : [
                                startSessionWatchers(),
                                startPermissionsWatchers(),
                                setManualScrollRestoration(),
                                initializeComplete(response.data),
                              ],
                      }),
                      fetchBranding(),
                    ],
            }),
          ];
        }),
        catchError((error: AxiosError) => {
          if (error.response && error.response.status === 401) {
            log.info('Session is no longer valid');
            return [defaultAction];
          } else {
            log.error('Failed to fetch session', error);
            return errorActions;
          }
        })
      );
    })
  );

export const completeEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(initializeComplete)),
    withLatestFrom(state$.pipe(map(state => state.router.location.pathname))),
    switchMap(([action, activatedLocationPath]) => {
      const session = action.payload.session;

      // If we're logged in:
      let actions: Action[] = [setInitializeComplete()];
      if (session) {
        actions = [growl(core_t(['login', 'loginSuccess'], { name: session.name })), ...actions];
      } else {
        actions = [
          ...actions,
          ...(activatedLocationPath === Routes.Exception ? [] : [navigateTo(Routes.Login)]),
        ];
      }
      hidePageLoader();

      // Set up an ongoing subscription to call closeDismissibleComponents after every location change.
      return action$.pipe(
        filter(isActionOf(locationChangeComplete)),
        switchMap(navAction => [
          closeDismissibleComponents(),
          ...(getSkipAutoScrollToTopForUrlPrefixes().find(prefix =>
            navAction.payload.location.pathname.startsWith(prefix)
          )
            ? []
            : [restoreScroll(navAction.payload.location.pathname)]),
        ]),
        startWith(...actions)
      );
    })
  );

export default combineEpics(initializeEpic, initializePendoEpic, inspectSessionEpic, completeEpic);
