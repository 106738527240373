import { useEffect, useState } from 'react';
import { DropdownMenuItem } from 'singlewire-components';
import {
  MultiSelectFilter,
  SearchAndPaginationFilters,
} from '../../../core/search-pager/SearchPagerActions';
import { SearchPagerMultiselectFilter } from '../components/TableContainer';
import { updateFiltersForMulti } from './SearchBarFilterUtils';

/**
 * This component contains the logic for the filter dropdown button.
 * It will render each option for the filter and allow the selection of
 * the filters to be applied.
 * @param filter
 * @param currentFilters
 * @param onApply
 * @constructor
 */
export const SearchBarMultiSelectFilter = ({
  filter,
  currentFilters,
  onApply,
}: {
  filter: SearchPagerMultiselectFilter<any>;
  currentFilters?: SearchAndPaginationFilters | null;
  onApply: (filters: SearchAndPaginationFilters | null) => void;
}) => {
  const [currentView, setCurrentView] = useState([] as Array<any>);
  const filterKey = filter.name;
  useEffect(() => {
    const currentFilter: MultiSelectFilter<any> | null | undefined =
      currentFilters && (currentFilters[filterKey] as MultiSelectFilter<any>);
    setCurrentView(currentFilter?.value || []);
  }, [currentFilters, filterKey]);

  return (
    <>
      {filter.options.map(x => (
        <DropdownMenuItem
          onClick={() => {
            const curFilters = currentView.includes(x)
              ? currentView.filter(option => option !== x)
              : [...currentView, x];
            setCurrentView(curFilters);
            onApply(updateFiltersForMulti(currentFilters, filter, curFilters));
          }}
          variant="checkmark"
          id={x}
          key={x}
          checked={currentView.includes(x)}
          label={filter.localizeOption(x)}
        />
      ))}
    </>
  );
};
