// This file is excluded from coverage because we couldn't find a way to mock
// jQuery's growl function.
import { AxiosError } from 'axios';
import { toast, ToastContent, ToastOptions, TypeOptions } from 'react-toastify';
import { Box, Icon, Typography } from 'singlewire-components';
import { MobileApiValidationError } from '../../mobile-api-types';
import { isValidValidationError } from '../forms/FormUtils';
import log from './log';

export interface GrowlServiceOptions {
  type?: TypeOptions | 'danger' | 'light' | 'dark';
}

const getIcon = (icon?: string) => {
  switch (icon) {
    case 'success':
      return <Icon.Success size="sm" />;
    case 'warning':
      return <Icon.Warning size="sm" />;
    case 'error':
      return <Icon.Error size="sm" />;
    case 'dark':
      return <Icon.Info color="white" size="sm" />;
    default:
      return <Icon.Info size="sm" />;
  }
};

export const Toast = (props: { icon: GrowlServiceOptions['type']; message: string }) => {
  return (
    <Box ml="sm">
      <Typography variant="subtitle" startIcon={getIcon(props.icon)}>
        {props.message}
      </Typography>
    </Box>
  );
};

/**
 * Shows a popup message to the user
 * @param {string} content the message to display to the user
 * @param {GrowlServiceOptions} opts options related to displaying the message
 */
export function growl(
  content: string | ToastContent | null,
  opts: GrowlServiceOptions = {},
  toastOverrides?: ToastOptions
) {
  const { type = 'light' } = opts;

  if (content) {
    const toastContent: ToastContent =
      typeof content === 'string' ? <Toast icon={type} message={content} /> : content;
    toast(toastContent, {
      position: 'top-center',
      type: type === 'danger' ? 'error' : (type as TypeOptions),
      autoClose: type === 'danger' ? 10000 : 5000,
      draggable: false,
      closeButton: true,
      hideProgressBar: true,
      style: { marginTop: '1rem' },
      icon: false,
      closeOnClick: true,
      ...toastOverrides,
    });
  }
}

export const processError = (error: AxiosError | any, fallbackErrorMessage?: string) => {
  const validationError = error.response?.data;
  if (isValidValidationError(validationError)) {
    const reasons: MobileApiValidationError[] = validationError.reason;
    return reasons.map(reason => growl(reason.message, { type: 'danger' }));
  } else {
    log.error(fallbackErrorMessage);
    return undefined;
  }
};

export default {
  growl,
};
