import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import { ApiRequest, ApiRequestParams } from '../../common-types';
import { ResolveApiAdvancedConfig } from '../../views/shared/ResolveDataSafely';
import { parseQueryParams } from './querystring';
import log from './log';
import { Location } from 'history';
import Routes from '../route/Routes';

// Match special params in the ApiRequestParams with the values from the route Match.
export const interpolateParamsFromRoute = (
  params: ApiRequestParams,
  routeParams: { [key: string]: any }
): ApiRequestParams => {
  return map(params, (param: string) => {
    if (startsWith(param, ':')) {
      const paramValue = routeParams[param.substring(1)];
      if (!paramValue) {
        log.error(
          'Failed to interpolate params from param map. Missing required params.',
          params,
          routeParams
        );
        throw Error('Failed to interpolate params from param map. Missing required params.');
      }
      return paramValue;
    }
    return param;
  });
};

/**
 * A function that takes an ApiRequest and returns an interpolated ApiRequest.
 * ex: interpolateApiRequest(['users', [':userId'], 'GET'], {userId: 'abc123uuid'}) => ['users', ['abc123uuid'], 'GET']
 * @param request an ApiRequest array
 * @param routeParams a mapping of the route variables to the actual values in the url
 */
export const interpolateApiRequest = (
  request: ApiRequest,
  routeParams: { [key: string]: any }
): ApiRequest => {
  const [resource, params, verb, opts = {}] = request;
  return [resource, interpolateParamsFromRoute(params, routeParams), verb, opts];
};

export const interpolateApiRequestConfig = (
  request: ResolveApiAdvancedConfig,
  routeParams: { [key: string]: any }
): ResolveApiAdvancedConfig => {
  const [resource, params, verb, opts = {}] = request.request;
  return {
    ...request,
    request: [resource, interpolateParamsFromRoute(params, routeParams), verb, opts],
  };
};

/**
 * Attempts to parse the location's search query params returning the parsed object
 * @param location the location to parse the query params from
 */
export const getSearchParams = (
  location?: Location<any>
): Record<string, string | string[]> | null => {
  try {
    const search = location?.search?.substr(1);
    return search ? parseQueryParams(search) : null;
  } catch (e) {
    return null;
  }
};

// Change ['triggers', [':triggerId'] 'GET'] => ['triggers', ['abc123-actual-id'] 'GET']
export const interpolateResolve = (
  resolve: { [key: string]: ApiRequest | ResolveApiAdvancedConfig } | undefined,
  routeParams: { [key: string]: any } | undefined
) =>
  resolve &&
  Object.keys(resolve).reduce(
    (acc, k) => ({
      ...acc,
      [k]: Array.isArray(resolve[k])
        ? interpolateApiRequest(resolve[k] as ApiRequest, routeParams || {})
        : interpolateApiRequestConfig(resolve[k] as ResolveApiAdvancedConfig, routeParams || {}),
    }),
    {}
  );

export const ON_LOGIN_DESTINATIONS_TO_IGNORE = [Routes.Exception];

export const formatOnLoginDestination = (destination: string) => {
  if (ON_LOGIN_DESTINATIONS_TO_IGNORE.includes(destination)) {
    return Routes.Index;
  } else {
    return destination;
  }
};
