import { Chip } from 'singlewire-components';
import {
  SearchAndPaginationFilters,
  SingleSelectFilter,
} from '../../../core/search-pager/SearchPagerActions';
import {
  MobileApiSite,
  MobileApiSiteBuilding,
  MobileApiSiteBuildingFloor,
  MobileApiSiteBuildingFloorZone,
} from '../../../mobile-api-types';
import { buildDescriptionString, getLocationObjectFromLowestLevelPossible } from '../SiteUtils';
import { ResolveDataSafely } from '../ResolveDataSafely';
import { SearchPagerFilter, SearchPagerLocationFilter } from '../components/TableContainer';
import { removeLocationFilters } from './SearchBarFilterUtils';

export const LocationFilterChip = ({
  filter,
  currentFilters,
  onSearchPagerChangeFilters,
}: {
  filter: SearchPagerFilter;
  currentFilters: SearchAndPaginationFilters;
  onSearchPagerChangeFilters: (filters: SearchAndPaginationFilters | null) => void;
}) => {
  const locationFilterDefinition = filter as SearchPagerLocationFilter;
  const siteId = (currentFilters[`${filter.name}siteId`] as SingleSelectFilter)?.value;
  const buildingId = (currentFilters[`${filter.name}buildingId`] as SingleSelectFilter)?.value;
  const floorId = (currentFilters[`${filter.name}floorId`] as SingleSelectFilter)?.value;
  const zoneId = (currentFilters[`${filter.name}zoneId`] as SingleSelectFilter)?.value;

  // Note - the keys in this Resolve object are also used in SearchBarLocationFilterContainer. If updated here, update there too
  // We build this resolve object up dynamically so we can use the filter's name
  // We only grab the "lowest level" here, if there's a zone, we only use that, otherwise if there's a floor, we only use that, etc
  const resolve = {};
  if (zoneId) {
    resolve[`${filter.name}zone`] = [
      'siteBuildingFloorZones',
      [siteId, buildingId, floorId, zoneId],
      'GET',
      { params: { includeParentNames: true } },
    ];
  } else if (floorId) {
    resolve[`${filter.name}floor`] = [
      'siteBuildingFloors',
      [siteId, buildingId, floorId],
      'GET',
      { params: { includeParentNames: true } },
    ];
  } else if (buildingId) {
    resolve[`${filter.name}building`] = [
      'siteBuildings',
      [siteId, buildingId],
      'GET',
      { params: { includeParentNames: true } },
    ];
  } else if (siteId) {
    resolve[`${filter.name}site`] = ['sites', [siteId], 'GET'];
  }
  return (
    <ResolveDataSafely
      resolve={resolve}
      render={renderObj => {
        const [siteTyped, buildingTyped, floorTyped, zoneTyped] = [
          renderObj[`${filter.name}site`] as MobileApiSite,
          renderObj[`${filter.name}building`] as MobileApiSiteBuilding,
          renderObj[`${filter.name}floor`] as MobileApiSiteBuildingFloor,
          renderObj[`${filter.name}zone`] as MobileApiSiteBuildingFloorZone,
        ];

        const location = getLocationObjectFromLowestLevelPossible(
          siteTyped,
          buildingTyped,
          floorTyped,
          zoneTyped
        );

        return (
          <Chip
            variant="outlined"
            color="primary"
            clickable={true}
            label={`${locationFilterDefinition.localizeName()}: ${buildDescriptionString(
              location?.site,
              location?.building,
              location?.floor,
              location?.zone
            )}`}
            onClick={() =>
              onSearchPagerChangeFilters(
                removeLocationFilters(locationFilterDefinition, currentFilters)
              )
            }
          />
        );
      }}
    />
  );
};
