import log from '../utils/log';

export const LOCAL_STORAGE_PAGER_KEY = 'admin-webapp.pagerLevel';

export const parsePagerLevel = (pagerLimit: string | null) => {
  try {
    return pagerLimit === null ? 'low' : pagerLimit;
  } catch (err) {
    log.error('Failed to parse from local storage!', err);
    return 'low';
  }
};

export const loadPagerLimit = (): string =>
  parsePagerLevel(localStorage.getItem(LOCAL_STORAGE_PAGER_KEY));

export const savePagerLimit = (
  pagerLimit: number,
  customLimits: null | [number, number, number]
) => {
  try {
    if (customLimits) {
      const customIndex = customLimits.indexOf(pagerLimit);

      if (customIndex === -1) {
        const index = [10, 20, 50].indexOf(pagerLimit);
        const level = index === 0 ? 'low' : index === 1 ? 'medium' : 'high';
        localStorage.setItem(LOCAL_STORAGE_PAGER_KEY, `${level}`);
      }

      const customLevel = customIndex === 0 ? 'low' : customIndex === 1 ? 'medium' : 'high';
      localStorage.setItem(LOCAL_STORAGE_PAGER_KEY, `${customLevel}`);
    } else {
      const index = [10, 20, 50].indexOf(pagerLimit);
      const level = index === 0 ? 'low' : index === 1 ? 'medium' : 'high';
      localStorage.setItem(LOCAL_STORAGE_PAGER_KEY, `${level}`);
    }
  } catch (err) {
    log.error('Failed to write to local storage!', err);
  }
};
