import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { common_t } from '../../../CommonLocale';
import {
  MultiSelectFilter,
  MultiSelectFilterType,
  SearchAndPaginationFilters,
  SingleSelectFilterTypes,
} from '../../../core/search-pager/SearchPagerActions';
import { MobileApiLocationObject } from '../../../mobile-api-types';
import {
  SearchPagerAttributeFilter,
  SearchPagerDateFilter,
  SearchPagerFilter,
  SearchPagerLocationFilter,
  SearchPagerMultiselectFilter,
  SearchPagerStringFilter,
} from '../components/TableContainer';

export enum DateViewMode {
  NONE = 'none',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_SEVEN_DAYS = 'lastSevenDays',
  LAST_THIRTY_DAYS = 'lastThirtyDays',
  LAST_TWELVE_MONTHS = 'lastTwelveMonths',
  CUSTOM_DATE = 'customDate',
  CUSTOM_DATE_RANGE = 'customDateRange',
}

export const DATE_VIEW_MODES = [
  DateViewMode.TODAY,
  DateViewMode.YESTERDAY,
  DateViewMode.LAST_SEVEN_DAYS,
  DateViewMode.LAST_THIRTY_DAYS,
  DateViewMode.LAST_TWELVE_MONTHS,
  DateViewMode.CUSTOM_DATE,
  DateViewMode.CUSTOM_DATE_RANGE,
];

export const localizeDateViewMode = (dateViewMode: DateViewMode) =>
  common_t(['label', `${dateViewMode.toString()}` as any]);

export const DEFAULT_DATE_VIEW_MODE = DateViewMode.LAST_THIRTY_DAYS;

export enum OrderMode {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const ORDER_MODES = [OrderMode.ASCENDING, OrderMode.DESCENDING];

export const localizeOrderMode = (mode: OrderMode | string) =>
  common_t(['label', `${mode.toString()}` as any]);

export const removeDateFilters = (
  filter: SearchPagerDateFilter,
  currentFilters: SearchAndPaginationFilters
): null | SearchAndPaginationFilters =>
  Object.keys(currentFilters).reduce((acc, currentKey) => {
    if (currentKey === `${filter.name}__gte` || currentKey === `${filter.name}__lt`) {
      return acc;
    } else {
      return { ...(acc || {}), [currentKey]: currentFilters![currentKey] };
    }
  }, null as null | SearchAndPaginationFilters);

export const updateFiltersForDate = (
  currentFilters: SearchAndPaginationFilters | null | undefined,
  filter: SearchPagerDateFilter,
  startFilter: string | null,
  endFilter: string | null
): SearchAndPaginationFilters | null => {
  const adjustedFilters = removeDateFilters(filter, currentFilters || {});
  const dateFilterType: SingleSelectFilterTypes = 'date';
  const finalFilters = {
    ...adjustedFilters,
    ...(startFilter
      ? { [`${filter.name}__gte`]: { type: dateFilterType, value: startFilter } }
      : {}),
    ...(endFilter ? { [`${filter.name}__lt`]: { type: dateFilterType, value: endFilter } } : {}),
  };
  return Object.keys(finalFilters).length === 0 ? null : finalFilters;
};

export const removeValueFromMultiFilter = (
  filter: SearchPagerFilter,
  currentFilters: SearchAndPaginationFilters,
  value: any
) =>
  Object.keys(currentFilters).reduce((acc, currentKey) => {
    if (currentKey === filter.name && filter.type === 'multi') {
      const multiFilter = currentFilters![currentKey] as MultiSelectFilter<any>;
      const filterAfterRemove = {
        ...filter,
        value: multiFilter.value.filter(currentVal => currentVal !== value),
      };
      return isEmpty(filterAfterRemove.value)
        ? acc
        : {
            ...(acc || {}),
            [currentKey]: filterAfterRemove,
          };
    } else {
      return { ...(acc || {}), [currentKey]: currentFilters![currentKey] };
    }
  }, null as null | SearchAndPaginationFilters);

export const removeFilter = (
  filter: SearchPagerFilter,
  currentFilters: SearchAndPaginationFilters
) =>
  Object.keys(currentFilters).reduce((acc, currentKey) => {
    if (currentKey === filter.name) {
      return acc;
    } else {
      return { ...(acc || {}), [currentKey]: currentFilters![currentKey] };
    }
  }, null as null | SearchAndPaginationFilters);

// prettier-ignore
export const getDateViewMode = (startFilter?: string, endFilter?: string): DateViewMode => {
  const today = moment().startOf('day');
  if (startFilter === today.toISOString() && !endFilter) {
    return DateViewMode.TODAY;
  } else if (startFilter === today.clone().subtract(1, 'days').toISOString() && endFilter === today.clone().toISOString()) {
    return DateViewMode.YESTERDAY;
  } else if (startFilter === today.clone().subtract(7, 'days').toISOString() && !endFilter) {
    return DateViewMode.LAST_SEVEN_DAYS;
  } else if (startFilter === today.clone().subtract(30, 'days').toISOString() && !endFilter) {
    return DateViewMode.LAST_THIRTY_DAYS;
  } else if (startFilter === today.clone().subtract(1, 'years').toISOString() && !endFilter) {
    return DateViewMode.LAST_TWELVE_MONTHS;
  } else if (startFilter && endFilter && moment(startFilter).add(1, 'days').toISOString() === endFilter) {
    return DateViewMode.CUSTOM_DATE;
  } else if (startFilter && endFilter) {
    return DateViewMode.CUSTOM_DATE_RANGE;
  } else {
    return DateViewMode.NONE;
  }
};

export const getOrderMode = (order?: string): OrderMode => {
  if (order === 'desc') {
    return OrderMode.DESCENDING;
  } else {
    return OrderMode.ASCENDING;
  }
};

export const updateFiltersForString = (
  currentFilters: SearchAndPaginationFilters | null | undefined,
  filter: SearchPagerStringFilter,
  order: string | null
): SearchAndPaginationFilters | null => {
  const adjustedFilters = removeFilter(filter, currentFilters || {});
  const stringFilterType: SingleSelectFilterTypes = 'string';
  const finalFilters = {
    ...adjustedFilters,
    ...(order ? { [filter.name]: { type: stringFilterType, value: order } } : {}),
  };
  return Object.keys(finalFilters).length === 0 ? null : finalFilters;
};

export const updateFiltersForAttribute = (
  currentFilters: SearchAndPaginationFilters | null | undefined,
  filter: SearchPagerAttributeFilter,
  option: string | null
): SearchAndPaginationFilters | null => {
  const adjustedFilters = removeFilter(filter, currentFilters || {});
  const attributeFilterType: SingleSelectFilterTypes = 'attribute';
  const finalFilters = {
    ...adjustedFilters,
    ...(option ? { [filter.name]: { type: attributeFilterType, value: option } } : {}),
  };
  return Object.keys(finalFilters).length === 0 ? null : finalFilters;
};

export const updateFiltersForMulti = (
  currentFilters: SearchAndPaginationFilters | null | undefined,
  filter: SearchPagerMultiselectFilter<any>,
  options: Array<string | null>
): SearchAndPaginationFilters | null => {
  const multiFilterType: MultiSelectFilterType = 'multi';
  const adjustedFilters = removeFilter(filter, currentFilters || {});
  const finalFilters = {
    ...(adjustedFilters || {}),
    ...(isEmpty(options)
      ? {}
      : {
          [filter.name]: {
            type: multiFilterType,
            value: options,
            transformFilterValue: filter.transformFilterValue,
          },
        }),
  };
  return Object.keys(finalFilters).length === 0 ? null : finalFilters;
};

/**
 * Return the currentFilters list with all Location filters for the given filter removed
 * Location filters are put into the currentFilters array with the keys `${filter.name}siteId`, `${filter.name}buildingId`, and so on for floor and zone
 * This will return the currentFilters list with all those keys gone
 */
export const removeLocationFilters = (
  filter: SearchPagerLocationFilter,
  currentFilters: SearchAndPaginationFilters
): SearchAndPaginationFilters =>
  Object.keys(currentFilters).reduce((acc, currentKey) => {
    if (
      currentKey === `${filter.name}siteId` ||
      currentKey === `${filter.name}buildingId` ||
      currentKey === `${filter.name}floorId` ||
      currentKey === `${filter.name}zoneId`
    ) {
      return acc;
    } else {
      return { ...acc, [currentKey]: currentFilters[currentKey] };
    }
  }, {});

/**
 * Take a Location filter and add the correct values to currentFilters based on the site, building, floor, and zone selected
 * These are put into the currentFilters array with keys of `${filter.name}siteId`, `${filter.name}buildingId`, and so on for floor and zone
 * This is so the conversion from currentFilters to API params works automatically
 */
export const updateFiltersForLocation = (
  currentFilters: SearchAndPaginationFilters | null | undefined,
  filter: SearchPagerLocationFilter,
  location?: MobileApiLocationObject
): SearchAndPaginationFilters | null => {
  const adjustedFilters = removeLocationFilters(filter, currentFilters || {});
  const locationFilterType: SingleSelectFilterTypes = 'location';
  const finalFilters = {
    ...adjustedFilters,
    ...(location?.site
      ? { [`${filter.name}siteId`]: { type: locationFilterType, value: location.site.id } }
      : {}),
    ...(location?.building
      ? {
          [`${filter.name}buildingId`]: { type: locationFilterType, value: location.building.id },
        }
      : {}),
    ...(location?.floor
      ? { [`${filter.name}floorId`]: { type: locationFilterType, value: location.floor.id } }
      : {}),
    ...(location?.zone
      ? { [`${filter.name}zoneId`]: { type: locationFilterType, value: location.zone.id } }
      : {}),
  };
  return Object.keys(finalFilters).length === 0 ? null : finalFilters;
};

const addInitialValue = (
  currentFilters: SearchAndPaginationFilters | null,
  filter:
    | SearchPagerDateFilter
    | SearchPagerStringFilter
    | SearchPagerAttributeFilter
    | SearchPagerLocationFilter
) => {
  if (typeof filter.initialValue === 'string') {
    return {
      ...(currentFilters || {}),
      [filter.name]: { type: filter.type, value: filter.initialValue },
    };
  } else {
    const init: { [key: string]: string } = filter.initialValue!;
    const initialFilters = Object.entries(init).reduce((acc, [key, value]) => {
      return { ...acc, [key]: { value: value, type: filter.type } };
    }, {});
    return {
      ...(currentFilters || {}),
      ...initialFilters,
    };
  }
};

/**
 * Populates filters with initial values specified in the filter definitions.
 *
 * If filter definition does not have an initial value, do nothing
 *
 * Multiselect filters do not currently support the initial value field because we
 * have not yet had the need for it.
 * @param filters
 */
export const buildFilters = (filters?: SearchPagerFilter[]) => {
  if (!filters) {
    return null;
  }
  return filters.reduce((acc, filter) => {
    if (
      filter.type === 'multi' ||
      filter.initialValue === null ||
      filter.initialValue === undefined
    ) {
      return acc;
    }
    return addInitialValue(acc, filter);
  }, null as SearchAndPaginationFilters | null);
};
