import { memo } from 'react';
import { GridContainer, GridItem } from 'singlewire-components';
import styled from 'styled-components';
import {
  AllFilterTypes,
  MultiSelectFilter,
  SearchAndPaginationFilters,
  SingleSelectFilter,
} from '../../../core/search-pager/SearchPagerActions';
import { exhaustivePatternMatchGuardReturns } from '../../../core/utils/typeUtils';
import { Chip } from '../components/Chip';
import {
  SearchPagerAttributeFilter,
  SearchPagerDateFilter,
  SearchPagerFilter,
  SearchPagerMultiselectFilter,
  SearchPagerStringFilter,
} from '../components/TableContainer';
import { SearchBarFilterDropdownContainer } from './SearchBarFilterDropdownContainer';
import {
  getDateViewMode,
  localizeDateViewMode,
  localizeOrderMode,
  removeDateFilters,
  removeFilter,
  removeValueFromMultiFilter,
} from './SearchBarFilterUtils';
import { LocationFilterChip } from './LocationFilterChip';

const FilterContainer = styled.div<{ $searchFieldHidden: boolean; $actionButtonHidden: boolean }>`
  display: inline-flex;
  flex-direction: row;
  margin-top: ${props => (props.$actionButtonHidden ? '0px' : '10px')};
  align-items: center;
  @media (min-width: 768px) {
    padding-bottom: 1rem;
  }
`;

interface FilterListItemProps {
  filter: SearchPagerFilter;
  onSearchPagerChangeFilters: (filters: SearchAndPaginationFilters | null) => void;
  currentFilters: SearchAndPaginationFilters;
}

export const FilterListItem = ({
  filter,
  currentFilters,
  onSearchPagerChangeFilters,
}: FilterListItemProps) => {
  // No chips to render if no current filters
  if (
    !currentFilters[filter.name]?.value &&
    !currentFilters[`${filter.name}__gte`]?.value &&
    !currentFilters[`${filter.name}__lt`]?.value &&
    !currentFilters[`${filter.name}siteId`]?.value &&
    !currentFilters[`${filter.name}buildingId`]?.value &&
    !currentFilters[`${filter.name}floorId`]?.value &&
    !currentFilters[`${filter.name}zoneId`]?.value
  ) {
    return <></>;
  }
  const filterType: AllFilterTypes['type'] = filter.type;
  switch (filterType) {
    case 'date':
      const startFilter = currentFilters[`${filter.name}__gte`] as SingleSelectFilter;
      const endFilter = currentFilters[`${filter.name}__lt`] as SingleSelectFilter;
      const dateFilter: SearchPagerDateFilter = filter as SearchPagerDateFilter;
      const dateViewMode = getDateViewMode(startFilter?.value, endFilter?.value);
      return !dateFilter.required ? (
        <Chip
          variant="outlined"
          color="primary"
          clickable={true}
          label={localizeDateViewMode(dateViewMode)}
          onClick={() => {
            onSearchPagerChangeFilters(removeDateFilters(dateFilter, currentFilters));
          }}
        />
      ) : (
        <Chip variant="outlined" color="primary" label={localizeDateViewMode(dateViewMode)} />
      );
    case 'string':
      const stringFilterDefinition = filter as SearchPagerStringFilter;
      const currentStringFilter = currentFilters[filter.name] as SingleSelectFilter;
      return (
        <Chip
          variant="outlined"
          color="primary"
          clickable={true}
          label={`${stringFilterDefinition.localizeName()} ${localizeOrderMode(
            currentStringFilter.value
          )}`}
          onClick={() => onSearchPagerChangeFilters(removeFilter(filter, currentFilters))}
        />
      );

    case 'attribute':
      const attributeFilterDefinition = filter as SearchPagerAttributeFilter;
      const currentAttributeFilter = currentFilters[filter.name] as SingleSelectFilter;
      return (
        <Chip
          variant="outlined"
          color="primary"
          clickable={true}
          label={`${attributeFilterDefinition.localizeName()}: ${attributeFilterDefinition.localizeOption(
            currentAttributeFilter.value
          )}`}
          onClick={() =>
            onSearchPagerChangeFilters(removeFilter(attributeFilterDefinition, currentFilters))
          }
        />
      );
    case 'location':
      return (
        <LocationFilterChip
          filter={filter}
          currentFilters={currentFilters}
          onSearchPagerChangeFilters={onSearchPagerChangeFilters}
        />
      );
    case 'multi':
      const multiFilterDefinition = filter as SearchPagerMultiselectFilter<any>;
      const currentMultiFilter = currentFilters[filter.name] as MultiSelectFilter<any>;
      const filterChips = currentMultiFilter.value.map(val => (
        <Chip
          variant="outlined"
          color="primary"
          clickable={true}
          label={`${multiFilterDefinition.localizeName()}: ${multiFilterDefinition.localizeOption(
            val
          )}`}
          onClick={() =>
            onSearchPagerChangeFilters(
              removeValueFromMultiFilter(multiFilterDefinition, currentFilters, val)
            )
          }
          key={val}
        />
      ));
      return <>{filterChips}</>;
    default:
      return exhaustivePatternMatchGuardReturns(filterType, <></>);
  }
};

interface SearchBarFilterContainerProps {
  onSearchPagerChangeFilters?: (filters: SearchAndPaginationFilters | null) => void;
  currentFilters?: SearchAndPaginationFilters | null;
  availableFilters: SearchPagerFilter[];
  searchFieldHidden: boolean;
  actionButtonHidden: boolean;
}

export const SearchBarFilterContainer = memo(
  ({
    onSearchPagerChangeFilters,
    currentFilters,
    availableFilters,
    searchFieldHidden,
    actionButtonHidden,
  }: SearchBarFilterContainerProps) => (
    <GridContainer>
      <GridItem xs={12} sm={4} md={6}>
        <FilterContainer
          $searchFieldHidden={searchFieldHidden}
          $actionButtonHidden={actionButtonHidden}>
          <SearchBarFilterDropdownContainer
            onSearchPagerChangeFilters={onSearchPagerChangeFilters!}
            availableFilters={availableFilters}
            currentFilters={currentFilters}
          />
        </FilterContainer>
      </GridItem>
    </GridContainer>
  )
);
